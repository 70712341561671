const SETTING = {
    prod: {
        apiUrl: 'mahjong.sweetbot.online/',
        firebaseConfig: {
            apiKey: "AIzaSyBpOo4gKdiueIBsqFwg69cK0N-v0xNqfYA",
            authDomain: "sml-game.firebaseapp.com",
            databaseURL: "https://sml-game-default-rtdb.firebaseio.com",
            projectId: "sml-game",
            storageBucket: "sml-game.appspot.com",
            messagingSenderId: "950607103327",
            appId: "1:950607103327:web:53f9d672f8b83d86b2a2ea",
            measurementId: "G-2WP3LY8X41"
        }
    },
    test: {
        apiUrl: 'mahjong.sweetbot.online/',
        firebaseConfig: {
            apiKey: "AIzaSyATPypr1tgyKnG-8-J-Tn-Er8JKmKJSB7I",
            authDomain: "boyplaymj-daily.firebaseapp.com",
            databaseURL: "https://boyplaymj-daily-default-rtdb.firebaseio.com",
            projectId: "boyplaymj-daily",
            storageBucket: "boyplaymj-daily.appspot.com",
            messagingSenderId: "397030678993",
            appId: "1:397030678993:web:2c1feaab999225039e00f0",
            measurementId: "G-WWHYE539RC"
        }
    },
    stage: {
        apiUrl: 'mahjong.sweetbot.online/',
        firebaseConfig: {
            apiKey: "AIzaSyATPypr1tgyKnG-8-J-Tn-Er8JKmKJSB7I",
            authDomain: "boyplaymj-daily.firebaseapp.com",
            databaseURL: "https://boyplaymj-daily-default-rtdb.firebaseio.com",
            projectId: "boyplaymj-daily",
            storageBucket: "boyplaymj-daily.appspot.com",
            messagingSenderId: "397030678993",
            appId: "1:397030678993:web:2c1feaab999225039e00f0",
            measurementId: "G-WWHYE539RC"
        }
    },
    version: "1.0.0",
    thousandSeparator: true,
    playoffYear: 2021,
    playoffMonth: 1,
    playoffDate: 23,
    decPlaces: 0,
    decimal: 0,
    color: {},
}
module.exports = SETTING
