// import Axios from './apis/_axios'


import login from './apis/login'

export default {
	...login,

}

