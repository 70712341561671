import Axios from './_axios'


export default {
	bindGooleAccount(getters, {credentials}) {
		const formdata = new FormData()

		formdata.append('dcid', credentials)
		let url = getters.apiUrl + 'GoogleOAuth/userAuthUrl'
		return Axios.post(
			url, formdata
		).then(res => {
			return res
		})
	},
}
