import Vue from 'vue'
import App from './App.vue'
import store from './store'
import eventBus from '@/eventBus'

Vue.config.productionTip = false

const params = new URLSearchParams(window.location.search)
const dcid = (params.get('dcid')) ? params.get('dcid') : '';


import(`@/router/`).then(module => {
    let router = module.default;

    window.vue = new Vue({
        data() {
            return {
                bus: eventBus,
                dcid:dcid,
            }
        },
        watch: {
        },
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
})
