const setting = require('../utils/config.js');

let initialState = {}

initialState = {
	setting: {
		version: setting.version,
		apiUrl: (process.env.NODE_ENV == 'production') ? setting.prod.apiUrl : setting.stage.apiUrl,
	},
}

export const state = initialState
