export const setting = state => state.setting

export const apiUrl = (state) => {
	return window.location.protocol + '//' + state.setting.apiUrl
}

// export const auth = (state) => {
//     return state.auth || {}
// }


