import axios from 'axios'
import api from '@/utils/_api'
// import assist from '@/components/assist/index.js'

const Axios = axios.create({
	config: {
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Headers': 'Content-Type'
		}
	}
});
Axios.interceptors.response.use(response => {
	const {data} = response;
	if (data.err) {
		throw data.err;
	}
});

export const bindGooleAccount = ({getters},credentials) => {
	return api.bindGooleAccount(getters,credentials)
		.then((res) => {
			return res.data
		}).then((e) => {
				return e;
			}
		)
}
